import moment from "moment";
import entities from "utils/entities";

const FORCE_DELETE_TIME_LIMIT = 15 * 60;

export const ManifestSchema = entities.schema.Definition("packManifest", {
  _idAttribute: "uid",
});

export const PackSchema = entities.schema.Definition("pack", {
  _idAttribute: "metadata.name",
  manifests: [ManifestSchema],
  spec: {
    manifests: [ManifestSchema],
  },
});
export const PackVersionSchema = entities.schema.Definition(
  "packVersion",
  {
    pack: PackSchema,
    manifests: [ManifestSchema],
    spec: {
      manifests: [ManifestSchema],
    },
  },
  {
    pack: (value, key, entity) => ({
      metadata: { name: entity.name, uid: entity.packUid },
    }),
    manifests: (value, key, entity) => value || [],
  }
);

export const ClusterProfileTemplateSchema = entities.schema.Definition(
  "clusterProfileTemplate",
  {
    packs: [PackVersionSchema],
  },
  {
    profile(value, key, entity) {
      return { metadata: entity.metadata };
    },
    profileRef(value, key, entity) {
      return entity.metadata;
    },
    metadata() {
      return undefined;
    },
  }
);

export const NodeSchema = entities.schema.Definition("node", {}, []);

export const NodePoolSchema = entities.schema.Definition(
  "nodePools",
  {
    _idAttribute: "uid",
    nodes: [NodeSchema],
  },
  {
    // this overwrite is necessary due to autoscaler feature
    minSize: (value, key, data) => {
      return value || undefined;
    },
    maxSize: (value, key, data) => {
      return value || undefined;
    },
    size: 0,
    isControlPlane: false,
    additionalLabels: (value, key, data) => {
      return value || {};
    },
  }
);

export const CloudAccountSchema = entities.schema.Definition(
  "cloudaccount",
  {},
  {
    name: "",
  }
);

export const CloudConfigSchema = entities.schema.Definition(
  "cloudConfig",
  {
    spec: {
      machinePoolConfig: [NodePoolSchema],
      cloudAccount: CloudAccountSchema,
    },
  },
  {
    spec: (value = {}, key, data) => ({
      ...value,
      machinePoolConfig: (value.machinePoolConfig || []).map((pool) => ({
        ...pool,
        uid: `${data.metadata.uid}_${pool.name}`,
      })),
      cloudAccount: {
        metadata: {
          uid: value.cloudAccountRef?.uid,
          name: value.cloudAccountRef?.name,
        },
      },
    }),
  }
);

export const NotificationEvent = entities.schema.Definition(
  "notificationEvent",
  {
    pack: PackVersionSchema,
    manifest: ManifestSchema,
  },
  {
    _idAttribute: "uid",
    pack(key, value, data) {
      return { metadata: { uid: data.packUid, name: data.packName } };
    },
    profile(key, value, data) {
      return { metadata: { uid: data.profileUid } };
    },
    manifest(key, value, data) {
      if (!data.manifestUid) {
        return undefined;
      }
      return {
        name: data.manifestName,
        uid: data.manifestUid,
        metadata: {
          uid: data.manifestUid,
          name: data.manifestName,
        },
        spec: {
          parentUid: data.manifestParentUid,
        },
      };
    },
  }
);

export const NotificationSchema = entities.schema.Definition(
  "notification",
  {
    events: [NotificationEvent],
  },
  {
    events(value, key, data) {
      const events = data?.action?.events || {};

      return Object.keys(events).map((key) => ({
        ...events[key],
        uid: key,
      }));
    },
  }
);

export const ClusterProfileSchema = entities.schema.Definition(
  "clusterprofile",
  {
    spec: {
      draft: ClusterProfileTemplateSchema,
      published: ClusterProfileTemplateSchema,
      packs: [PackSchema],
    },
    notifications: [NotificationSchema],
  },
  {
    spec(value, key, entity) {
      return { ...entity.specSummary, ...(value || {}) };
    },
  }
);

NotificationEvent.define({
  profile: ClusterProfileSchema,
});

ClusterProfileTemplateSchema.define({
  profile: ClusterProfileSchema,
});

export const ClusterSchema = entities.schema.Definition(
  "cluster",
  {
    spec: {
      cloudConfig: CloudConfigSchema,
      clusterprofile: ClusterProfileSchema,
      clusterProfileTemplate: ClusterProfileTemplateSchema,
      clusterProfileTemplates: [ClusterProfileTemplateSchema],
    },
    notifications: [NotificationSchema],
  },
  {
    spec(value) {
      if (!value) {
        return {};
      }
      const spec = { ...value };
      if (value.clusterProfileRef) {
        spec.clusterprofile = {
          metadata: value.clusterProfileRef,
        };
      }

      if (value.cloudConfigRef) {
        spec.cloudConfig = {
          metadata: value.cloudConfigRef,
        };
      }

      return spec;
    },
    status(value) {
      if (!value) {
        return {};
      }

      const status = { ...value };

      if (status?.abortTimestamp) {
        status.forceDeleteTimeRemaining =
          FORCE_DELETE_TIME_LIMIT -
          moment().diff(value.abortTimestamp, "seconds");
      }

      return status;
    },
  }
);

export const EdgeMachineSchema = entities.schema.Definition(
  "edgeMachine",
  {
    spec: {
      clusterProfileTemplates: [ClusterProfileTemplateSchema],
    },
  },
  {
    metadata(value, key, data) {
      return {
        ...value,
        labels: value.labels || {},
      };
    },
  }
);

export const BackupLocationSchema = entities.schema.Definition(
  "backuplocation",
  {}
);
export const RegionSchema = entities.schema.Definition("region", {});
export const CredentialSchema = entities.schema.Definition("credential", {});
export const SSHSchema = entities.schema.Definition("ssh", {});
export const RepositorySchema = entities.schema.Definition("repository");
export const HelmRepositorySchema =
  entities.schema.Definition("helmRepository");
export const VpcidSchema = entities.schema.Definition("vpcid");

export const EventSchema = entities.schema.Definition("clusterevent", {}, {});
export const AuditSchema = entities.schema.Definition("auditlogs", {}, {});
export const WorkspaceSchema = entities.schema.Definition("workspace", {}, {});

export const CertificateSchema = entities.schema.Definition(
  "certificate",
  {},
  {}
);

export const MaintenancePoliciesSchema = entities.schema.Definition(
  "maintenancepolicies",
  {},
  {}
);

export const AlertChannelSchema = entities.schema.Definition(
  "alertChannel",
  {},
  {}
);

export const ProjectSchema = entities.schema.Definition(
  "project",
  {
    spec: {
      alerts: [{ channels: [AlertChannelSchema] }],
    },
  },
  {}
);
export const ProjectPermissionsSchema = entities.schema.Definition(
  "projectPermissions",
  {
    _idAttribute: "projectUid",
    project: ProjectSchema,
  },
  {
    project(key, value, entity) {
      return { metadata: { uid: entity.projectUid } };
    },
  }
);

export const FilterSchema = entities.schema.Definition("filter", {}, {});

export const RoleSchema = entities.schema.Definition("role", {}, {});
export const ProjectRolesSchema = entities.schema.Definition(
  "projectRoles",
  {
    project: ProjectSchema,
    roles: [RoleSchema],
    inheritedRoles: [RoleSchema],
  },
  {}
);

export const TenantRolesSchema = entities.schema.Definition(
  "tenantRoles",
  {},
  {}
);

export const WorkspaceRolesSchema = entities.schema.Definition(
  "workspaceRoles",
  {
    project: ProjectSchema,
    roles: [RoleSchema],
    workspaces: [WorkspaceSchema],
  },
  {}
);

export const ResourceRolesSchema = entities.schema.Definition(
  "resourceRoles",
  {
    projects: [ProjectSchema],
    roles: [RoleSchema],
    filters: [FilterSchema],
  },
  {}
);

export const UserSchema = entities.schema.Definition(
  "user",
  {
    spec: {
      roles: [RoleSchema],
      projects: [ProjectSchema],
    },
  },
  {
    spec: (value = {}) => ({
      ...value,
      projects: entities.schema.UidStrings(value.projects || []),
      teams: entities.schema.UidStrings(value.teams || []),
      roles: entities.schema.UidStrings(value.roles || []),
    }),
  }
);

export const TeamSchema = entities.schema.Definition(
  "team",
  {
    spec: {
      roles: [RoleSchema],
      projects: [ProjectSchema],
      users: [UserSchema],
    },
  },
  {
    spec: (value = {}) => ({
      ...value,
      projects: entities.schema.UidStrings(value.projects || []),
      users: entities.schema.UidStrings(value.users || []),
      roles: entities.schema.UidStrings(value.roles || []),
    }),
  }
);

export const MemberSchema = entities.schema.Definition("member", {}, {});

export const OverlordSchema = entities.schema.Definition("overlord", {}, {});

export const DatacenterSchema = entities.schema.Definition(
  "datacenter",
  {},
  {
    datacenter: (value, key, data) => data.name || data.datacenter,
  }
);

export const PackRegistriesSchema = entities.schema.Definition(
  "packregistries",
  {},
  {}
);

export const HelmRegistriesSchema = entities.schema.Definition(
  "helmregistries",
  {},
  {}
);

export const OCIRegistriesSchema = entities.schema.Definition(
  "ociregistries",
  {},
  {}
);

export const IPPoolSchema = entities.schema.Definition("ipPool", {}, {});

export const SSHKeysSchema = entities.schema.Definition("sshKeys", {}, {});
export const DNSMappingSchema = entities.schema.Definition(
  "dnsmapping",
  {},
  {}
);

export const InvoiceProjectSchema = entities.schema.Definition(
  "invoiceProject",
  {
    _idAttribute: "projectUid",
    project: ProjectSchema,
  },
  {
    project(key, value, entity) {
      return { metadata: { uid: entity.projectUid } };
    },
  }
);

export const InvoiceSchema = entities.schema.Definition("invoice", {
  spec: {
    project: [InvoiceProjectSchema],
  },
});

export const ClusterScanSchema = entities.schema.Definition("scan", {});
export const BackupSchema = entities.schema.Definition(
  "backup",
  {
    _idAttribute: "uid",
  },
  {}
);

export const RestoreStatusSchema = entities.schema.Definition(
  "restoreStatus",
  {},
  {}
);

export const CertificateAuthoritySchema = entities.schema.Definition(
  "certificateAuthority",
  {},
  {}
);

export const RoleBindingSchema = entities.schema.Definition(
  "clusterRoleBinding",
  {},
  {}
);

export const NamespacesSchema = entities.schema.Definition(
  "namespaces",
  {},
  {}
);

export const PodSchema = entities.schema.Definition("pod", {}, {});
export const DeploymentSchema = entities.schema.Definition(
  "deployment",
  {},
  {}
);
export const DaemonSetSchema = entities.schema.Definition("daemonSet", {}, {});
export const StatefulSetSchema = entities.schema.Definition(
  "statefulSet",
  {},
  {}
);
export const JobSchema = entities.schema.Definition("job", {}, {});
export const CronJobSchema = entities.schema.Definition("cronJob", {}, {});
export const ClusterNamespaceSchema = entities.schema.Definition(
  "clusterNamespace",
  {},
  {}
);
export const AuditTrailSchema = entities.schema.Definition("audittrails", {});
export const APIKeysSchema = entities.schema.Definition(
  "apikeys",
  {},
  {
    status: (value) => {
      return {
        ...value,
        isActive: value.isActive || false,
      };
    },
  }
);

export const WorkloadNamespaceSchema = entities.schema.Definition(
  "workload/namespace",
  {
    _idAttribute: "metadata.entity.uid",
  }
);

export const WorkloadRoleBindingSchema = entities.schema.Definition(
  "workload/rolebinding",
  {
    _idAttribute: "metadata.entity.uid",
  }
);

export const WorkloadClusterSchema = entities.schema.Definition(
  "workload/cluster",
  {
    _idAttribute: "clusterUid",
  }
);

export const WorkloadVirtualClusterSchema = entities.schema.Definition(
  "workload/virtualCluster",
  {}
);

export const MacroSchema = entities.schema.Definition("macro", {}, {});

export const ClusterGroupSchema = entities.schema.Definition(
  "clusterGroup",
  {
    spec: {
      clusterprofile: ClusterProfileSchema,
      clusterProfileTemplate: ClusterProfileTemplateSchema,
      clusterProfileTemplates: [ClusterProfileTemplateSchema],
    },
  },
  {
    spec(value) {
      if (!value) {
        return {};
      }
      const spec = { ...value };
      if (value.clusterProfileRef) {
        spec.clusterprofile = {
          metadata: value.clusterProfileRef,
        };
      }

      return spec;
    },
  }
);

export const HostClusterSchema = entities.schema.Definition(
  "hostCluster",
  {},
  {}
);

export const GeocodingLocationSchema = entities.schema.Definition(
  "geocoding",
  {
    _idAttribute: "id",
  },
  {}
);

export const TierSchema = entities.schema.Definition(
  "appTier",
  {
    _idAttribute: (entity) => {
      return entity.uid ? "uid" : "metadata.uid";
    },
    spec: {
      pack: PackSchema,
    },
  },
  {
    spec(value, key, data) {
      if (!data.spec) {
        return value;
      }

      return {
        ...value,
        pack: {
          metadata: {
            name: data.spec.sourceAppTierUid,
          },
        },
      };
    },
  }
);

export const AppProfileSchema = entities.schema.Definition(
  "appProfile",
  {
    spec: {
      appTiers: [TierSchema],
      template: {
        appTiers: [TierSchema],
      },
    },
  },
  {}
);

export const AppDeploymentSchema = entities.schema.Definition(
  "appDeployment",
  {},
  {}
);

export const RegistrationTokensSchema = entities.schema.Definition(
  "registrationTokens",
  {
    user: UserSchema,
  },
  {
    user: (value, key, data) => ({
      metadata: { uid: data.metadata.annotations.ownerUid },
    }),
  }
);
