import React, { useState, useMemo, useRef, useImperativeHandle } from "react";
import styled, { css } from "styled-components";
import { Tooltip } from "antd";
import isEmpty from "lodash/isEmpty";

import Loader from "components/ui/Loader";
import { TextButton } from "components/ui/Button";
import Icon from "components/ui/Icon";
import { white } from "utils/constants/colors";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import PresetsSider from "./Presets";
import Toolbar from "./Toolbar";
import { userSettingsCache } from "services/localstorage/cache";

const EditorComponent = React.lazy(() => import("./Editor"));

const Wrap = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
`;

const NotificationsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #a9a9a9;
  color: #fff;

  .anticon {
    vertical-align: middle;
    margin-right: 10px;
    color: inherit;
  }
  svg {
    max-width: 16px;
    path {
      fill: #fff;
    }
  }

  ${TextButton} {
    min-width: 40px;
    color: #fff;
  }
`;

const MessageWrap = styled.div`
  display: flex;
  align-items: center;

  a {
    color: #fff;
    text-decoration: underline;
  }
`;

const IconWrap = styled.div`
  margin: 0 7px;
  font-size: 18px;

  ${(props) =>
    props.fill &&
    css`
      svg path {
        fill: ${props.fill};
      }
    `}
`;

const EditorWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export default function Editor({
  showActions = true,
  title = "",
  customToolbarButtons = null,
  headerColor = white,
  notifications = [],
  showNotifications,
  acknowledgeNotifications,
  onRevert,
  showRevertOption,
  showPresets,
  presets,
  presetsDisabledFields,
  onPresetsChange,
  showDiffToggle,
  showDiff,
  presetsPathName,
  theme,
  onClose,
  passedInRef,
  ...rest
}) {
  const editorRef = useRef();
  const [editorTheme, setTheme] = useState(
    userSettingsCache.get("editorTheme") || "light"
  );
  const [diffDisplay, setDiffDisplay] = useState(false);
  const actionableNotifications = useMemo(() => {
    return notifications.filter((notification) => notification.message);
  }, [notifications]);

  useImperativeHandle(passedInRef, () => {
    return {
      refresh() {
        editorRef.current.refresh();
      },
      focus() {
        editorRef.current?.focus();
      },
    };
  });

  function toggleTheme() {
    const newTheme = editorTheme === "light" ? "dark" : "light";
    userSettingsCache.set("editorTheme", newTheme);
    setTheme(newTheme);
  }

  function renderNotification(notification) {
    if (!notification.message) {
      return null;
    }
    return (
      <MessageWrap>
        {notification.icon && <Icon {...notification.icon} />}{" "}
        <div>{notification.message}</div>
      </MessageWrap>
    );
  }

  function renderNotificationIcons(notification) {
    if (!notification.icon) {
      return null;
    }
    return (
      <IconWrap fill={notification?.icon?.fill}>
        <Tooltip title={notification.message}>
          <Icon {...notification.icon} />
        </Tooltip>
      </IconWrap>
    );
  }

  function renderNotifications() {
    if (!showNotifications || !actionableNotifications.length) {
      return null;
    }
    return (
      <NotificationsWrapper>
        <div>{actionableNotifications.map(renderNotification)}</div>
        <TextButton onClick={acknowledgeNotifications}>
          <Icon awesome={faTimesCircle} />
        </TextButton>
      </NotificationsWrapper>
    );
  }

  const toolbarProps = {
    title,
    headerColor,
    theme,
    customToolbarButtons,
    showActions,
    showDiffToggle,
    showRevertOption,
    onRevert,
    onClose,
    notifications,
    renderNotificationIcons,
    editorRef,
    editorTheme,
    toggleTheme,
    diffDisplay,
    setDiffDisplay,
    ...rest,
  };

  return (
    <Wrap>
      <Toolbar {...toolbarProps} />
      {renderNotifications()}
      <React.Suspense fallback={<Loader />}>
        <EditorWrapper>
          {showPresets && !isEmpty(presets) && (
            <PresetsSider
              presets={presets}
              onChange={onPresetsChange}
              presetsPathName={presetsPathName}
              disabledFields={presetsDisabledFields}
            />
          )}
          <EditorComponent
            {...rest}
            showDiff={showDiffToggle ? diffDisplay : showDiff}
            theme={editorTheme}
            refresh={!showNotifications}
            passedInRef={editorRef}
          />
        </EditorWrapper>
      </React.Suspense>
    </Wrap>
  );
}
