import isEqual from "fast-deep-equal";
import { createSelector } from "reselect";

export const getEnabledSSO = createSelector(
  (state) => state.forms,
  ({ saml, oidc }) => {
    if (saml?.data?.persisted && saml?.data?.isSsoEnabled) {
      return "saml";
    }

    if (oidc?.data?.persisted && oidc?.data?.isSsoEnabled) {
      return "oidc";
    }
  }
);

export const isRepavedOIDC = createSelector(
  (state) => state.forms?.oidc?.data,
  (state) => state.forms?.oidc?.initialData,
  (data, initialData) => {
    return !isEqual(data, initialData);
  }
);
